import React, { useState } from 'react';

import {loginUser} from './api';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser({username: username, password: password});
  };

  return (
    <div className='user-form-container'>
      <div className="login-page">
        <div className="form">
          <form className="login-form">
            <h1>Welcome back</h1>
            <input type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
            <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
            <button onClick={handleSubmit}>Continue</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

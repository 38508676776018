import { createSlice } from '@reduxjs/toolkit';

export const chatsSlice = createSlice({
  name: 'chats',
  initialState: {
    chats: {},
    customersMessage: '',
    chatsMessage: '',
    customersError: '',
    chatsError: '',
    customers: {}
  },
  reducers: {
    updateChat: (state, action) => {
      state.chats[action.payload.id] = action.payload;
    },
    setChats: (state, action) => {
      state.chats = {};
      action.payload.forEach(chat => {
        state.chats[chat.id] = chat;
      });
    },
    setCustomersMessage: (state, action) => {
      state.customersMessage = action.payload;
    },
    setChatsMessage: (state, action) => {
      state.chatsMessage = action.payload;
    },
    setCustomersError: (state, action) => {
      state.customersError = action.payload;
    },
    setChatsError: (state, action) => {
      state.chatsError = action.payload;
    },
    setCustomers: (state, action) => {
      action.payload.forEach(customer => {
        state.customers[customer.id] = customer;
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateChat, setChats, setCustomersMessage, setChatsMessage, setCustomersError, setChatsError, setCustomers } = chatsSlice.actions;

export default chatsSlice.reducer;

// src/api.js
import axios from 'axios';
import store from '../store'; // Import the Redux store
import { setChats, setCustomersMessage, setChatsMessage, setCustomersError, setChatsError, setCustomers } from '../slice/chats';
import { setChatId, setMessages, resetMessages, setPropertyData } from '../slice/messages';

// TODO: put this is .env file
const HOST = 'https://api.pocketisa.com/';
const API_URL = `${HOST}api/`;

const requests = (method, url, data={}, state='chats') => {
  axios({
    method,
    url: `${API_URL}${url}`,
    data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  }).then((response) => {
    if (state === 'chats') {
      if (response.data.results){
        store.dispatch(setChats(response.data.results));
      }
    }
    else if (state === 'messages') {
      if (response.data.results){
        store.dispatch(setMessages(response.data.results));
        getPropertyData(response.data.results[0].chat);
      }
      document.querySelector('.scrollView').scrollIntoView({behavior: "smooth"});
    }
    else if (state === 'customers') {
      if (response.data.results){
        store.dispatch(setCustomers(response.data.results));
      }
    }
    else if (state === 'message') {
      getMessages(response.data.data.chat);
      // get url last part
      const myChatId = window.location.href.split('/').pop();
      if (myChatId !== response.data.data.chat) {
        store.dispatch(setChatId(response.data.data.chat));
        window.history.pushState(null, null, `/c/${response.data.data.chat}`);
        getChats();
      }
    }
    else if (state === 'login') {
      if (response.data.access) {
        console.log('login', response.data.access);
        localStorage.setItem('token', response.data.access);
        window.location.href = '/c/new';
      }
    }
    else if (state === 'bookmark') {
      getChats();
    }
    else if (state === 'chat') {
      if (response.data.id) {
        store.dispatch(resetMessages());
        window.history.pushState(null, null, `/c/${response.data.id}`);
        getMessages(response.data.id);
        store.dispatch(setChatId(response.data.id));
        getChats();
      }
    }
    else if (state === 'customer') {
      if (response.data.id) {
        store.dispatch(setCustomersMessage(`#${data.id}## ${data.phone_number} ${state} created successfully`));
        store.dispatch(setCustomersError(''));
      }
    }
    else if (state === 'property-data') {
      if (response.data.results && response.data.results.length > 0) {
        store.dispatch(setPropertyData(response.data.results[0]));
      }else{
        store.dispatch(setPropertyData({}));
      }
    }
  }).catch((error) => {
    console.error('Error:', error);
    console.error('Error:', error.response);
    console.error('Error:', error.message);
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/u/login';
    }
    if (state === 'customer') {
      store.dispatch(setCustomersMessage(error.response.data.message));
      store.dispatch(setCustomersError('red-text'));
    }
    else if (state === 'message') {
      getMessages(data['chat_id']);
      // get url last part
      const myChatId = window.location.href.split('/').pop();
      if (myChatId !== data['chat_id']) {
        store.dispatch(setChatId(data['chat_id']));
        window.history.pushState(null, null, `/c/${data['chat_id']}`);
        getChats();
      }
    }
    else if (state === 'chat') {
      store.dispatch(setChatsMessage(error.response.data.message));
      store.dispatch(setChatsError('red-text'));
    }
    else if (state === 'login') {
      store.dispatch(setCustomersMessage(error.response.data.message));
      store.dispatch(setCustomersError(true));
    }
  });
};

const getChats = (params='') => {
  requests('get', `chats/?page_size=100${params}`);
}

const getMessages = (chatId) => {
  requests('get', `messages/?chat_id=${chatId}&page_size=100`, {}, 'messages');
};

const sendMessage = (data) => {
  requests('POST', 'messages/', data, 'message');
};

const loginUser = (data) => {
  requests('POST', 'token/', data, 'login');
}

const createChat = (data) => {
  requests('POST', 'chats/', data, 'chat');
}

const createCustomer = (data) => {
  requests('POST', 'customers/', data, 'customer');
}

const getPropertyData = (chatId) => {
  requests('GET', `property-data/?chat_id=${chatId}`, {}, 'property-data');
}

const getCustomers = () => {
  requests('GET', 'customers/?page_size=100', {}, 'customers');
}

export { requests, getChats, getMessages, sendMessage, loginUser, createChat, createCustomer, getPropertyData, getCustomers };

import React from 'react';

const Message = ({ message, role }) => {
  return (
    <>
      <div className="message user">
        <p>{message.inbound_message}</p>
      </div>
      <div className="message bot">
        <p>{message.outbound_message}</p>
      </div>
    </>
  );
};

export default Message;

// import components here
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Message from './components/messages';
import History from './components/history';
import PropertyData from './components/propertyData';
import {getChats, getMessages, sendMessage, createChat, createCustomer, getCustomers} from './components/api';
import {setChatId, updateMessages, resetMessages} from './slice/messages';

import './App.css';

function App() {
  const chats = useSelector((state) => state.chats.chats);
  const messages = useSelector((state) => state.messages.messages);
  const propertyData = useSelector((state) => state.messages.propertyData);
  const customersMessage = useSelector((state) => state.chats.customersMessage);
  const chatsMessage = useSelector((state) => state.chats.chatsMessage);
  const customersError = useSelector((state) => state.chats.customersError);
  const chatsError = useSelector((state) => state.chats.chatsError);
  const chatId = useSelector((state) => state.messages.chatId);
  const customers = useSelector((state) => state.chats.customers);
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const handleEnterSearch = (event) => {
    if (event.keyCode === 13) {
      // prevent default behavior of enter key
      event.preventDefault();
      getChats(`&search=${searchValue}`);
    }
  };

  const handleHumanTakeover = (event) => {
    getChats(`&human_takeover=${event.target.checked}`);
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13) {
      // prevent default behavior of enter key
      event.preventDefault();
      dispatch(updateMessages({ id: 'extra-id', inbound_message: inputValue.toString(), outbound_message: 'Loading...' }));
      setInputValue('');
      sendMessage({
        inbound_message: inputValue,
        sender_is_aibot: true,
        chat_id: chatId,
      });
    }
  };
  function openForm(formId) {
    document.getElementById(formId).style.display = "block";
    document.getElementById(`${formId}Button`).style.display = "none";
  }
  function closeForm(formId) {
    document.getElementById(formId).style.display = "none";
    document.getElementById(`${formId}Button`).style.display = "block";
  }
  function createNew(formId) {
    const form = document.getElementById(`${formId}Form`);
    const formData = new FormData(form);
    const data = {};
    for (const [key, value] of formData.entries()) {
      data[key] = value;
    }
    if (data['rentals'] === 'rentals'){
      data['rentals'] = true;
    }else{
      data['rentals'] = false;
    }
    if (data['spanish'] === 'spanish'){
      data['spanish'] = true;
    }else{
      data['spanish'] = false;
    }
    if (formId === 'chats'){
      createChat(data);
    }
    else if (formId === 'customers'){
      createCustomer(data);
    }
    closeForm(formId);
    // clear messages
    dispatch(resetMessages());
  }

  useEffect(() => {
    // select input field by name
    document.querySelector('textarea[name="newMessage"]').focus();
    let myId = window.location.href.split('/').pop();
    if (myId === ''){
      window.history.pushState(null, null, `/c/new`);
      myId = 'new';
    }
    dispatch(setChatId(myId));
    // get all the chats from backend
    getChats();
    getCustomers();
    // get messages based on chatId from backend
    if (myId !== 'new'){
      getMessages(myId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <main>
        <div key="empty"></div>
        <div className="conversation">
          {
            Object.values(messages).map((content) => {
              return <Message key={`${content.id}-message`} message={content} />;
            })
          }
        </div>
        <div className="scrollView"></div>
      </main>
      <aside>
        <div className="history">
          <div className="persona-col">
            <div className={customersError}>{customersMessage}</div>
            <button className="open-button" id="customersButton" onClick={()=>openForm('customers')}>Create New Persona</button>
            <div className="form-popup" id="customers">
              <form className="form-container" id="customersForm">
                <input type="text" placeholder="bot name" name="bot_name" required/>
                <input type="text" placeholder="team name" name="team_name" required/>
                <input type="text" placeholder="brokerage" name="brokerage" required/>
                <input type="text" placeholder="website" name="website" required/>
                <input type="text" placeholder="service area" name="service_area" required/>
                <input type="text" placeholder="phone number" name="phone_number" required/>
                <input type="text" placeholder="email address" name="email_address" required/>
                <input type="number" placeholder="price floor" name="price_floor" required/>
                <input type="checkbox" name="rentals" value="rentals"/>
                <label htmlFor="rentals">Rentals</label><br/>
                <input type="checkbox" name="spanish" value="spanish"/>
                <label htmlFor="spanish">Spanish</label><br/>
                <button type="button" className="btn" onClick={()=>createNew('customers')}>Create</button>
                <button type="button" className="btn cancel" onClick={()=>closeForm('customers')}>Close</button>
              </form>
            </div>
          </div>
          <div className="chat-col">
            <div className={chatsError}>{chatsMessage}</div>
            <button className="open-button" id="chatsButton" onClick={()=>openForm('chats')}>Create New Chat</button>
            <div className="form-popup" id="chats">
              <form className="form-container" id="chatsForm">
                <input type="text" placeholder="lead id" name="lead_id" required/>
                <input type="text" placeholder="lead phone" name="lead_phone" required/>
                <select name="client_phone" id="client_phone">
                  {
                    Object.values(customers).map((customer) => {
                      return <option key={`${customer.id}-customer`} value={customer.phone_number}>{customer.phone_number}</option>;
                    })
                  }
                </select>

                <select name="lead_type" id="lead_type">
                  <option value="buyer">Buyer</option>
                  <option value="seller">Seller</option>
                </select>
                <input type="text" placeholder="system text" name="system_text" required/>
                <input type="text" placeholder="lead response" name="lead_response" required/>
                <button type="button" className="btn" onClick={()=>createNew('chats')}>Create</button>
                <button type="button" className="btn cancel" onClick={()=>closeForm('chats')}>Close</button>
              </form>
            </div>
          </div>
          <div className="data-col">
            <div id="dataMessage"></div>
            <button type="button" className="open-button" id="dataButton" onClick={()=>openForm('data')}>Expand data</button>
            <div className="form-popup" id="data">
              <div id="property-data">
                <PropertyData propertyData={propertyData} key="property-data" />
              </div>
              <button type="button" className="btn cancel" onClick={()=>closeForm('data')}>Hide Data</button>
            </div>
          </div>
          {/* search input field */}
          <input type="text" placeholder="Search.." name="search" id="search"
            onKeyDown={handleEnterSearch}
            onChange={handleSearch} />
          {/* checkbox to filter human take over */}
          <label htmlFor="human_takeover">Completed</label>
          <input type="checkbox" onChange={handleHumanTakeover} name="human_takeover" id="human_takeover"/>
          <ul className='chat-history'>
            {
              Object.values(chats).reverse().map((chat) => {
                return <History key={`${chat.id}-chat-his`} chat={chat} isActive={chatId===chat.id ? true : false } />;
              })
            }
          </ul>
        </div>
      </aside>
      <footer>
        <div className="new-message">
          <textarea name="newMessage" value={inputValue} placeholder='Enter new message ...'
            onChange={handleInputChange}
            onKeyDown={handleEnterPress}
            className="newMessage" cols="30"></textarea>
          <input type="hidden" name="chatId" value="{{ chat_id }}"/>
        </div>
      </footer>
    </div>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit'

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: {},
    chatId: 'new',
    propertyData: {},
  },
  reducers: {
    updateMessages: (state, action) => {
      state.messages[action.payload.id] = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = {};
      action.payload.forEach(message => {
        state.messages[message.id] = message;
      });
    },
    resetMessages: (state) => {
      state.messages = {};
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setPropertyData: (state, action) => {
      state.propertyData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateMessages, setMessages, setChatId, resetMessages, setPropertyData } = messagesSlice.actions;

export default messagesSlice.reducer;

import React from 'react';
import { useDispatch } from 'react-redux';
import { setChatId, resetMessages } from '../slice/messages';
import { getMessages } from './api';

const History = ({ chat, isActive }) => {
  const dispatch = useDispatch();

  const changeChatBookmark = (event) => {
    // send put fetch request to backend
    event.preventDefault();
    dispatch(resetMessages());
    window.history.pushState(null, null, `/c/${chat.id}`);
    getMessages(chat.id);
    // set id in state
    dispatch(setChatId(chat.id));
  };

  return (
    <li className={isActive ? 'active click-div' : 'click-div'}>
      <div id={chat.id} onClick={(e) => changeChatBookmark(e)}>
        {`#${chat.id} - ${chat.lead_phone}`}
      </div>
    </li>
  );
};

export default History;

import React from 'react';

const PropertyData = ({ propertyData }) => {

  const showKeys = [ 'buy_property_type', 'buy_area', 'buy_budget', 'sell_property_value', 'pre_approved', 'time_frame', 'under_contract', 'both_actions'];

  return (
    <>
    <div key="lead-type"><b>Lead Type:{propertyData["action_type"]}</b></div>
    {
      Object.keys(propertyData).map((myKey) => {
        if (["sell_address", "buy_area"].includes(myKey) && propertyData[myKey]) {
          return <><div key={`${myKey}-one`}><b>{myKey}</b></div>
            {
              Object.keys(propertyData[myKey]).map((minKey) => {
                if (propertyData[myKey][minKey] && ["address", "city", "state", "zip_code"].includes(minKey)
                ){
                  return <div key={`${myKey}-${minKey}-one`}>{minKey}:{propertyData[myKey][minKey]}</div>
                }else{
                  return <div key={`${myKey}-${minKey}-empty`}></div>
                }
              })
            }
          </>;
        } else if (["sell_amenities", "buy_amenities"].includes(myKey) && propertyData[myKey] && propertyData[myKey]['all_amenities']) {
          return <><div key={`${myKey}-amenities-one`}><b>{myKey}</b></div>
            {
              Object.keys(propertyData[myKey]['all_amenities']).map((minKey) => {
                if (propertyData[myKey]['all_amenities'][minKey]){
                  return <div key={`${myKey}-${minKey}-amenities-one`}>{minKey}:{propertyData[myKey]['all_amenities'][minKey]}</div>
                }else{
                  return <div key={`${myKey}-${minKey}-amenities-empty`}></div>
                }
              })
            }
          </>;
        } else if (showKeys.includes(myKey) && propertyData[myKey] !== null) {
          return <div key={`${myKey}-one-else`}>{myKey}:{propertyData[myKey]}</div>
        }else{
          return <div key={`${myKey}-one-empty`}></div>
        }
      })
    }
    </>
  );
};

export default PropertyData;
